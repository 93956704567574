import React from "react"
import FeesRebatesContent from "../components/content/FeesRebatesContent"
import AutumnBGImage from "../components/AutumnBGImage"

export default function fees() {
  return (
    <div>
      <FeesRebatesContent />
    </div>
  )
}
