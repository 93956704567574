import React from "react"
import Layout from "../Layout"
import AutumnBGImage from "../AutumnBGImage"

export default function FeesRebatesContent() {
  return (
    <Layout>
      <AutumnBGImage style={{}}>
        <div className="hero-image-canvas"></div>
      </AutumnBGImage>
      <div className="contents-wrapper">
        <div className="text-container">
          <h1>Fees and rebates</h1>
          <div>
            <h2>Individual sessions</h2>
            <p>
              An initial consultation appointment is $300. This is for a 75 minute
              session.
            </p>
            <p>
              Subsequent appointments are 50 minutes in duration and are charged at $240 per session. The
              remainder of the hour is used to write clinical notes and any
              necessary letters, and to review your file.
            </p>
          </div>
          <div>
            <h2>Couples Sessions</h2>
            <p>Couples sessions are $300 for a 75 minute appointment.</p>
          </div>
          <div>
            <h2>Clinical Supervision</h2>
            <p>
              Clinical Supervision sessions are $225 for a 50 minute appointment
              (inclusive of GST).
            </p>
          </div>
          <div>
            <h2>Cancellation fees</h2>
            <p>
              If you are unable to attend your appointment please give as much
              notice as possible so the appointment time can be offered to
              someone else in need.
            </p>
            <p>
              Cancellations with less than 24 hours' notice will incur a
              cancellation fee of $100.
            </p>
            <p>
              If no notice of cancellation is given and a client fails to attend
              the appointment the full fee will be charged.
            </p>
            <p>Rebates cannot be claimed for cancellation fees.</p>
          </div>

          <div>
            <p>
              Please note: Clinical Psychology sessions are exempt from GST
              charges but other types of work e.g. supervision and some types of
              report writing are not exempt from GST.
            </p>
          </div>

          <div>
            <h2>Session Fees</h2>
            <p>
              Full payment is required at the time of consultation and the
              preferred method of payment is credit card.
            </p>
          </div>
          <h1>Rebates</h1>
          <div>
            <h2>Medicare rebates</h2>

            <p>
              <em>
                <b>Please note that Medicare does not provide a rebate for Couples Therapy.</b>
              </em>
            </p>

            <p>
              Clinical Psychology sessions are eligible for a rebate under the
              Medicare Better Access programme (MBA) with a valid Mental Health
              Care Plan (MHCP).
            </p>
            <p>
              Medicare will reimburse $136.35 per standard consultation with a
              Clinical Psychologist. You will be required to pay the full amount
              on the day of your appointment and Medicare will provide your
              rebate directly to your bank account.
            </p>

            <p>
              For a standard consultation this leaves you with an out of pocket
              expense of $103.65. Rebates are provided for 10 individual sessions
              and 10 group sessions per calendar year. Please see your General
              Practitioner to discuss getting a MHCP.
            </p>

            <p>
              If the doctor deems you eligible they will provide a MHCP and a
              referral letter for six sessions. When the six sessions have been
              used you can attend a review with the referring GP and if required
              they can write a re-referral letter for an additional four
              sessions.
            </p>

            <p>
              Under Medicare policy the Clinical Psychologist must receive a
              copy of the referral letter and the Mental Health Care Plan in
              order for the rebate to be processed. If paperwork is not received
              the session is not eligible for a rebate.
            </p>
            <p>
              Psychiatrists can also make referrals to Psychologists under the
              Better Access scheme. Should a psychiatrist refer you, only a
              referral letter is required.
            </p>
          </div>
          <div>
            <h2>Private Health Insurance Rebates</h2>
            <p>
              Should you wish to attend sessions and pay privately, no referral
              is required.
            </p>
            <p>
              Some private health insurance companies provide rebates for
              Clinical Psychology services, but this will be dependent on the
              insurer and the level of cover.
            </p>
            <p>
              Please contact your insurance company for details. Please note you
              cannot claim rebates from Medicare and Private Health
              concurrently.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
